import React from 'react';
import { Button } from '@mui/material';
import { useGoogleClient } from './googleClientProvider/GoogleClientProvider';
import { Google } from '@mui/icons-material';
import { useGoogleUserInfo } from './googleClientProvider/useGoogleUserInfo';

interface AttachGoogleAccountButtonProps {
}

const AttachGoogleAccountButton: React.FC<
  AttachGoogleAccountButtonProps
> = () => {
  const googleUserInfo = useGoogleUserInfo();
  const googleClient = useGoogleClient();

  const handleAttachCalendar = async () => {
    await googleClient.connect();
  };

  return (
    <>
      {!googleUserInfo.data && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<Google />}
          onClick={handleAttachCalendar}
        >
          Подключить Google календарь
        </Button>
      )}
    </>
  );
};

export default AttachGoogleAccountButton;
