import { Timestamp } from "firebase/firestore";

export const mapping: Record<string, string> = {
  id: 'id',
  paidAccessUntil: 'pau',
  userId: 'uid',
  accountId: 'aid',
  balance: 'bal',
  startTime: 'st',
  endTime: 'et',
  date: 'd',
  title: 't',
  name: 'nm',
  phone: 'phn',
  wishes: 'wsh',
  nextPayment: 'np',
  experienceId: 'eid',
  description: 'desc',
  duration: 'dur',
  link: 'lnk',
  availability: 'av',
  availabilities: 'avs',
  weekDays: 'wd',
  limitSettings: 'ls',
  dateAvailability: 'da',
  futureBookingDaysLimit: 'fbd',
  minimumNoticePeriod: 'mnp',
  dailyBookingLimit: 'dbl',
  gapBetweenEvents: 'gbe',
  onlyConsecutiveReservations: 'ocr',
  email: 'em',
  telegramId: 'tgid',
  timeSlots: 'ts',
  vkId: 'vkid',
  googleAccount: 'ga',
  refreshToken: 'rt',
  accessToken: 'at',
  expiryDate: 'ed',
  scope: 'sc',
  idToken: 'idt',
  tokenType: 'tt',
  calendarId: 'ci',
  createEvent: 'ce',
  hideCustomerInfo: 'hci',
  timezone: 'tz',
  timerange: 'tr',
  timeranges: 'trs',
  start: 's',
  end: 'e',
  googleCalendarEventId: 'geid',
  googleCalendarSettings: 'gcs',
  calendarIdForNewEvents: 'cid',
  invitees: 'ie',
  calendarIdsForAvailability: 'cifa',
  createGoogleMeetLink: 'cgl',
};

export function mapToDbFields(obj: any): Record<string, any> {
  const dbFields: Record<string, any> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const mappedKey = mapping[key] || key;
      if (Array.isArray(obj[key])) {
        dbFields[mappedKey] = obj[key].map((item: any) =>
          typeof item === 'object' && item !== null ? mapToDbFields(item) : item
        );
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        dbFields[mappedKey] = mapToDbFields(obj[key]);
      } else if (obj[key] instanceof Date) {
        dbFields[mappedKey] = Timestamp.fromDate(obj[key]);
      } else {
        dbFields[mappedKey] = obj[key];
      }
    }
  }
  return dbFields;
}

export function mapFromDbFields<T>(dbFields: Record<string, any>): T {
  const experience: Partial<any> = {};
  for (const key in dbFields) {
    const experienceKey = Object.keys(mapping).find((k) => mapping[k] === key);
    if (experienceKey) {
      if (Array.isArray(dbFields[key])) {
        experience[experienceKey] = dbFields[key].map((item: any) =>
          typeof item === 'object' && item !== null
            ? mapFromDbFields(item)
            : item
        );
      } else if (typeof dbFields[key] === 'object' && dbFields[key] !== null) {
        experience[experienceKey] = mapFromDbFields(dbFields[key]);
      } else if (dbFields[key] instanceof Timestamp) {
        experience[experienceKey] = dbFields[key].toDate();
      } else {
        experience[experienceKey] = dbFields[key];
      }
    }
  }
  return experience as T;
}

export function getDbName<T>(key: keyof T): string {
  return mapping[key.toString()];
}
