import React from 'react';
import { Box, Typography, Button, Avatar } from '@mui/material';
import AttachGoogleAccountButton from './AttachGoogleAccountButton';
import { useGoogleClient } from './googleClientProvider/GoogleClientProvider';
import Section from './Section';
import toast from 'react-hot-toast';
import { useGoogleUserInfo } from './googleClientProvider/useGoogleUserInfo';

type GoogleCalendarSettingsViewEditProps = {
  userId: string;
};

const SettingsGoogleViewEdit: React.FC<
  GoogleCalendarSettingsViewEditProps
> = () => {
  const googleClient = useGoogleClient();
  const googleUserInfo = useGoogleUserInfo();
  const handleDisconnect = async () => {
    try {
      await googleClient.disconnect();
    } catch (error) {
      toast.error('Failed to disconnect Google account');
    }
  };


  return (
    <Section title={'Google'}>
        <Box display="flex" alignItems="center" p={1}>
          {googleUserInfo.data ? (
            <>
              <Avatar
                src={googleUserInfo.data.picture}
                alt="User Avatar"
                sx={{ marginRight: 2 }}
              />
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {googleUserInfo.data.name}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDisconnect}
              >
                Disconnect
              </Button>
            </>
          ) : (
            <AttachGoogleAccountButton />
          )}
        </Box>
    </Section>
  );
};

export default SettingsGoogleViewEdit;
