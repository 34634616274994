import React, { useEffect, useState } from 'react';

import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Box,
  Divider,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from '../PhoneInput';
import useUpsertBooking from '../../queryHelpers/useUpsertBooking';
import useBooking from '../../queryHelpers/useBooking';
import useExperience from '../../queryHelpers/useExperience';
import BookingConfirmationDialog from './BookingConfirmationDialog';
import { BookingVM, DateTimeSlotVM } from '../../viewModels';
import { useUserContext } from '../../contexts/UserContext';
import { DateTime } from 'luxon';
import { useTimeZone } from '../../contexts/TimeZoneContext';
import { DayCalendarSelector } from '../experienceDateAvailability/DayCalendarSelector';
import TimeSlotSelector from './TimeSlotSelector';
import useTimeSlotsForSelection from '../../hooks/useTimeSlotsForSelection';

interface BookingFormProps {
  experienceId: string;
  bookingId?: string;
  onSave: (bookingId: string) => void;
}

const BookingFormFormSchema = yup
  .object({
    timeSlot: yup
      .object<DateTimeSlotVM | null>({
        start: yup.number().required('Укажите время'),
        end: yup.number().required('Укажите время'),
      })
      .required('Укажите время'),
    name: yup.string().required('Укажите имя'),
    email: yup.string().email('Неверный email').required('Укажите email'),
    phone: yup
      .string()
      .min(11, 'Укажите полный номер телефона')
      .required('Укажите номер телефона'),
    wishes: yup.string(),
  })
  .required();

export type BookingFormFormInput = Pick<
  BookingVM,
  'name' | 'phone' | 'wishes' | 'email'
> &
  Partial<Pick<BookingVM, 'timeSlot'>>;

const BookingForm: React.FC<BookingFormProps> = (props) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const {
    register,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<BookingFormFormInput>({
    defaultValues: {
      email: '',
      name: '',
      phone: '',
      wishes: '',
    },
    resolver: yupResolver(BookingFormFormSchema) as any,
  });
  const clientName = watch('name');
  const { timezone } = useTimeZone();

  const { user } = useUserContext();
  const experienceQuery = useExperience({
    id: props.experienceId,
    link: props.experienceId,
  });
  const bookingQuery = useBooking(props.bookingId);
  const upsertBookingMutation = useUpsertBooking();
  const [selectedDate, setSelectedDate] = useState<DateTime>(() =>
    DateTime.now().setZone(timezone).startOf('day'),
  );

  const timeSlotsForSelection = useTimeSlotsForSelection(
    selectedDate,
    experienceQuery.data,
    bookingQuery.data,
    timezone,
  );

  useEffect(() => {
    if (clientName === '' && user) {
      setValue('name', user.name);
    }
  }, [user, clientName, setValue]);

  const handleBook = async () => {
    trigger();
    if (!isValid) {
      return;
    }

    setOpenConfirmation(true);
  };

  const handleConfirm = async () => {
    setOpenConfirmation(false);
    await upsertBookingMutation.mutateAsync(
      {
        ...bookingQuery.data,
        ...watch(),
        experienceId: experienceQuery.data?.id!,
        id: props.bookingId,
      },
      {
        onSettled: () => setOpenProgress(false),
        onSuccess: (bookingId) => {
          props.onSave(bookingId);
        },
      },
    );
  };

  return (
    <Grid container alignItems={'center'} justifyContent={'center'}>
      <Paper sx={{ p: 2, width: '50vh' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {experienceQuery.data?.summary.title}
            </Typography>
            <Typography variant="overline">
              {experienceQuery.data?.summary.description}
            </Typography>
            <Divider flexItem sx={{ ml: -2, mr: -2 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" align="center">
              На какой день хотите записаться?
            </Typography>
            <DayCalendarSelector
              availabilities={timeSlotsForSelection}
              value={selectedDate}
              onChange={(x) => setSelectedDate(x!)}
              disableEmptyDates={true}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: -4 }}>
            <Box>
              <Typography variant="caption" align="center">
                На какое время хотите записаться?
              </Typography>
              <Controller
                name="timeSlot"
                control={control}
                render={({ field }) => (
                  <TimeSlotSelector
                    slots={timeSlotsForSelection?.filter(
                      (x) =>
                        selectedDate &&
                        x.start.setZone(timezone).hasSame(selectedDate, 'day'),
                    )}
                    value={field.value ?? null}
                    onChange={(value) => field.onChange(value)}
                    helperText={errors.timeSlot?.message}
                    error={!!errors.timeSlot}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">Укажите пожелания</Typography>
            <TextField
              multiline
              size="small"
              {...register('wishes')}
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">Укажите email адрес</Typography>
            <Box>
              <TextField
                size="small"
                {...register('email')}
                error={!!errors.email}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              Укажите, как к Вам обращаться
            </Typography>
            <TextField
              size="small"
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              Укажите номер телефона для связи
            </Typography>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  value={field.value}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleBook}
            >
              Записаться
            </Button>
          </Grid>
        </Grid>

        {experienceQuery.data && isValid && (
          <BookingConfirmationDialog
            open={openConfirmation}
            onConfirm={handleConfirm}
            booking={watch()}
            experience={experienceQuery.data?.summary!}
            onCancel={() => setOpenConfirmation(false)}
          />
        )}

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openProgress}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Paper>
    </Grid>
  );
};

export default BookingForm;
