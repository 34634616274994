import { useState } from 'react';
import TimeSlotSelector from '../bookingForm/TimeSlotSelector';
import { BookingVM } from '../../viewModels';
import useExperience from '../../queryHelpers/useExperience';
import { Box, FormHelperText } from '@mui/material';
import { Label } from '../../styled/Label';
import { Controller, UseFormReturn } from 'react-hook-form';
import { BookingScheduleFormInputs } from './BookingScheduleViewEdit';
import { DayCalendarSelector } from '../experienceDateAvailability/DayCalendarSelector';
import { DateTime } from 'luxon';
import useTimeSlotsForSelection from '../../hooks/useTimeSlotsForSelection'; // Import the new hook
import { useTimeZone } from '../../contexts/TimeZoneContext';

type Props = {
  experienceId: string;
  booking?: BookingVM;
  form: UseFormReturn<BookingScheduleFormInputs>;
};

const BookingScheduleEditor: React.FC<Props> = (props) => {
  const { timezone } = useTimeZone();
  const [selectedDate, setSelectedDate] = useState<DateTime | undefined>(() => {
    if (props.booking) {
      return props.booking.timeSlot.start
        .setZone(timezone)
        .setZone(DateTime.now().zone, { keepLocalTime: true });
    }
    return DateTime.now().startOf('day').setZone(timezone);
  });

  const experienceQuery = useExperience({
    id: props.experienceId,
    link: props.experienceId,
  });

  // Use the new hook to get time slots for selection
  const timeSlotsForSelection = useTimeSlotsForSelection(
    selectedDate ?? null,
    experienceQuery.data,
    props.booking,
    timezone,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'stretch',
      }}
    >
      <Box
        sx={{
          flex: 'auto',
          ml: -3,
        }}
      >
        <DayCalendarSelector
          availabilities={timeSlotsForSelection ?? []}
          value={selectedDate ?? undefined}
          onChange={(value) => setSelectedDate(value)}
          disableEmptyDates={true}
        />
      </Box>

      <Box sx={{ flex: 'auto' }}>
        <Label>Время</Label>

        <Controller
          name="timeSlot"
          control={props.form.control}
          render={({ field }) => (
            <TimeSlotSelector
              slots={timeSlotsForSelection?.filter(
                (x) => selectedDate && x.start.hasSame(selectedDate, 'day'),
              )}
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          )}
        />
        {props.form.formState.errors.timeSlot && (
          <FormHelperText error>
            {props.form.formState.errors.timeSlot?.message}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default BookingScheduleEditor;
