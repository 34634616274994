import { DateTime } from 'luxon';
import { DateString } from 'shared-ts/entities';

export type FieldType = 'phone' | 'email' | 'name' | 'wishes';

export interface ExperienceVM {
  id: string | undefined;
  accountId: string;
  summary: SummaryVM;
  dayAvailability: DayAvailabilityVM[];
  limitSettings: LimitSettingsVM;
  availabilities: DateTimeSlotVM[];
  dateAvailability: DateAvailabilityVM[];
  googleCalendarSettings?: GoogleCalendarSettingsVM | null;
}

export interface GoogleCalendarSettingsVM {
  calendarIdForNewEvents: string | null;
  invitees: string[] | null;
  createGoogleMeetLink: boolean;
  calendarIdsForAvailability: string[] | null;
}

export interface SummaryVM {
  title: string;
  description: string | null;
  duration: number | null;
  link: string;
}

export interface LimitSettingsVM {
  futureBookingDaysLimit: number | null;
  minimumNoticePeriod: number | null;
  dailyBookingLimit: number | null;
  gapBetweenEvents: number | null;
  onlyConsecutiveReservations: boolean;
}

export interface ExperienceFieldVM {
  field: FieldType;
  display: boolean;
  required: boolean;
}

export interface DayAvailabilityVM {
  weekDays: number[];
  timeSlots: TimeSlotVM[];
}

export interface DateAvailabilityVM {
  date: DateString;
  timeSlots: TimeSlotVM[];
}

export function createNewExperienceVM(): ExperienceVM {
  return {
    id: undefined,
    accountId: 'account_id_placeholder',
    summary: {
      title: '',
      description: '',
      link: '',
      duration: 0,
    },
    availabilities: [],
    dayAvailability: [],
    dateAvailability: [],
    limitSettings: {
      futureBookingDaysLimit: 30,
      minimumNoticePeriod: 60,
      dailyBookingLimit: 10,
      gapBetweenEvents: 15,
      onlyConsecutiveReservations: false,
    },
    googleCalendarSettings: null,
  };
}

export interface TimeSlotVM {
  start: number;
  end: number;
}

export interface DateTimeSlotVM {
  start: DateTime;
  end: DateTime;
}

export interface UserVM {
  id: string | undefined;
  accountId: string;
  name: string;
  email: string;
  telegramId: number;
  timezone: string;
}

export interface AccountVM {
  id: string | undefined;
  paidAccessUntil: DateString;
  balance: number;
  nextPayment: DateString;
  googleAccount?: GoogleAccountVM | null;
}

export interface BalanceTransactionsVM {
  amount: number;
  date: Date;
}

export interface GoogleAccountVM {
  accessToken: string;
  expiryDate: DateTime;
  idToken: string;
  refreshToken: string;
  scope: string;
  tokenType: string;
}

export interface BookingVM extends BookingScheduleVM {
  id: string | undefined;
  experienceId: string;
  createdIn: DateString;
  email: string;
  name: string;
  phone: string;
  wishes: string;
}

export interface BookingScheduleVM {
  timeSlot: DateTimeSlotVM;
}
